<template>
  <network-list />
</template>

<script>
export default {
  name: 'Networks',
  components: {
    NetworkList: () => import('src/admanager/components/NetworkList'),
  },
}
</script>
